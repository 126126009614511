import React from "react";
import Modal from "react-modal";
import {
  MdOutlineBusinessCenter,
  MdOutlineSchool,
  MdOutlineWorkOutline,
} from "react-icons/md";
import UseData from "../../Hooks/UseData";
import { BsXCircle } from "react-icons/bs";

Modal.setAppElement("#root");

const ResumeCardTwo = () => {
  const { educationArray, experiencesArray, sideProjectsArray } = UseData();
  const { local, handleExperienceModalData, isOpen, setIsOpen, singleData } =
    UseData();

  const handleModal = (id) => {
    handleExperienceModalData(id);
  };

  return (
    <>
      <div>
        {/* <!-- Experience contain --> */}
        <div className="flex items-center space-x-2 mb-4">
          <div className="text-6xl text-[#F95054]">
            <MdOutlineBusinessCenter />
          </div>
          <h4 className="text-5xl dark:text-white font-medium"> Experience </h4>
        </div>

        {experiencesArray.map((item) => (
          <div
            className="py-4 pl-5 pr-3 space-y-2 mb-6 rounded-lg dark:border-[#212425] dark:border-2 cursor-pointer group"
            style={{
              background: `${local === "dark" ? "transparent" : item?.bg}`,
            }}
            key={item.id}
            onClick={() => handleModal(item.id)}
          >
            <span className="text-tiny text-gray-lite dark:text-[#b7b7b7]">
              {item.date}
            </span>
            <h3 className="text-xl dark:text-white duration-300 transition group-hover:text-[#FA5252] dark:group-hover:text-[#FA5252]">
              {item.title}
            </h3>
            <p className="dark:text-[#b7b7b7]">{item.place}</p>
          </div>
        ))}
      </div>
      <div>
        {/* <!-- Side Projects contain --> */}
        <div className="flex items-center space-x-2 mb-4">
          <div className="text-6xl text-[#F95054]">
            <MdOutlineWorkOutline />
          </div>
          <h4 className="text-5xl dark:text-white font-medium">
            {" "}
            Side Projects{" "}
          </h4>
        </div>

        {sideProjectsArray.map((item) => (
          <div
            className="py-4 pl-5 pr-3 space-y-2 mb-6 rounded-lg  dark:border-[#212425] dark:border-2 cursor-pointer group"
            style={{
              background: `${local === "dark" ? "transparent" : item?.bg}`,
            }}
            key={item.id}
            onClick={() => handleModal(item.id)}
          >
            <span className="text-tiny text-gray-lite dark:text-[#b7b7b7]">
              {item.date}
            </span>
            <h3 className="text-xl dark:text-white duration-300 transition group-hover:text-[#FA5252] dark:group-hover:text-[#FA5252]">
              {item.title}
            </h3>
            <p className="dark:text-[#b7b7b7]">{item.place}</p>
          </div>
        ))}
      </div>
      <div>
        <div className="flex items-center space-x-2 mb-4">
          <div className="text-6xl text-[#F95054]">
            <MdOutlineSchool />
          </div>
          <h4 className="text-5xl dark:text-white font-medium"> Education </h4>
        </div>
        {/* end flex */}

        {educationArray.map((item) => (
          <div
            className="py-4 pl-5 pr-3 space-y-2 mb-6 rounded-lg  dark:border-[#212425] dark:border-2"
            style={{
              background: `${local === "dark" ? "transparent" : item?.bg}`,
            }}
            key={item.id}
          >
            <span className="text-tiny text-gray-lite dark:text-[#b7b7b7]">
              {item.date}
            </span>
            <h3 className="text-xl dark:text-white"> {item.title} </h3>
            <p className="dark:text-[#b7b7b7]">{item.place}</p>
          </div>
        ))}
      </div>

      {/* Portfolio modal start */}

      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        className=" outline-none flex items-center  p-4 md:p-8  rounded-2xl my-8"
      >
        <div className=" w-full md:w-10/12 lg:w-[850px] bg-white dark:bg-[#323232] mx-auto rounded-xl p-4 md:p-8 absolute left-1/2 top-1/2 transform -translate-x-[50%] -translate-y-[50%] shadow-lg ">
          <div className=" overflow-y-scroll max-h-[80vh] no-scrollbar flex flex-col gap-2">
            {/* close button */}
            <BsXCircle
              onClick={() => setIsOpen(false)}
              className="text-7xl cursor-pointer  absolute right-2 -top-12 md:-right-10 md:-top-6 z-50  text-white transition transform hover:rotate-45 duration-300 ease-in-out "
            />
            <h2 className="text-[#ef4060] dark:hover:text-[#FA5252] text-4xl font-bold">
              {singleData.title}
            </h2>
            <p className="dark:text-white text-[20px] sm:text-2lg ">
              <span className="font-semibold transition-all duration-300 ease-in-out hover:text-[#ef4060] ">
                <a
                  href={singleData?.companyLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {singleData?.place}
                </a>
              </span>
            </p>
            <p className="text-tiny text-gray-lite dark:text-[#b7b7b7] ">
              {singleData?.date}
            </p>
            {singleData?.description && (
              <div className="">
                <p className="dark:text-white text-[15px] sm:text-lg mb-2">
                  Description
                </p>
                <p className="dark:text-white text-2line font-light text-[15px] sm:text-sm whitespace-pre-line">
                  {singleData?.description}
                </p>
              </div>
            )}
            {!singleData?.description && (
              <>
                <div className="">
                  <p className="dark:text-white text-[15px] sm:text-lg mb-px">
                    Responsibilities
                  </p>
                  {singleData?.responsabilities?.map((item) => (
                    <p className="dark:text-white ml-2 text-2line font-light text-[15px] sm:text-sm mb-px">
                      ● {item}
                    </p>
                  ))}
                </div>
                <div className="">
                  <p className="dark:text-white text-[15px] sm:text-lg mb-px">
                    Key Accomplishments
                  </p>
                  {singleData?.keyAccomplishments?.map((item) => (
                    <p className="dark:text-white ml-2 text-2line font-light text-[15px] sm:text-sm mb-px">
                      ● {item}
                    </p>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>

      {/* Portfolio modal end */}
    </>
  );
};

export default ResumeCardTwo;
